<script setup lang="ts">
import { useNotification } from "naive-ui"
import { useRouter } from "vue-router"
import { logAsNotification, useSupabaseClient } from "#imports"
import { Database } from "~/composables/generated/supabaseTypes"

const supabase = useSupabaseClient<Database>()
const router = useRouter()
const notifications = useNotification()

async function logout() {
    const res = await supabase.auth.signOut()
    if (res.error) {
        logAsNotification(res.error, notifications)
    } else {
        notifications.success({
            title: "Logout erfolgreich",
            content: "Du wurdest erfolgreich abgemeldet.",
            duration: 2000,
            keepAliveOnHover: false,
        })
        await router.replace({ path: "/auth/signup" })
    }
}
</script>

<template>
    <div>
        <n-button secondary block type="error" @click="logout"> Logout</n-button>
    </div>
</template>

<style scoped lang="scss"></style>
