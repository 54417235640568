<script setup lang="ts">
import { useDialog, useLoadingBar, useMessage, useNotification } from "naive-ui"
import { onMounted } from "vue"
import { useRoute } from "vue-router"
import { NaiveUiInjections } from "~/plugins/vueErrorhandler"
import { useNuxtApp } from "#imports"

const load = useLoadingBar()
const route = useRoute()

onMounted(() => {
    const nuxt = useNuxtApp()
    const vueAppGlobalConfig = nuxt.vueApp.config.globalProperties
    vueAppGlobalConfig.$naiveUi = {
        notification: useNotification(),
        message: useMessage(),
        loadingBar: useLoadingBar(),
        dialog: useDialog(),
    } as NaiveUiInjections
})
</script>

<template>
    <div class="flex flex-col w-full h-full">
        <div v-if="!route.path.startsWith('/auth')">
            <navigation-bar />
        </div>
        <div class="flex flex-grow basis-full overflow-hidden">
            <div class="flex-grow basis-full relative">
                <!--suppress JSUnusedGlobalSymbols -->
                <lazy-nuxt-page
                    class="max-h-full max-w-full min-w-full min-h-full"
                    :keepalive="false"
                    :transition="{
                        name: 'page',
                        mode: 'out-in',
                        onEnterCancelled: () => load.error(),
                        onLeaveCancelled: () => load.error(),
                        onBeforeEnter: () => load.finish(),
                        onBeforeLeave: () => load.start(),
                    }"
                />
            </div>
        </div>
    </div>
</template>

<style lang="scss">
.page-enter-from {
    opacity: 0;
    // transform: translateX(100 px);
}

.page-enter-active {
    transition: all 0.3s ease-out;
}

.page-leave-to {
    opacity: 0;
    // transition: translateX(- 100 px);
}

.page-leave-active {
    transition: all 0.3s ease-in;
}
</style>
