<script setup lang="ts">
import { MenuOption, useThemeVars } from "naive-ui"
import { h, ref } from "vue"
import { useRoute } from "vue-router"
import { NuxtLink } from "#components"

const themeVars = useThemeVars()

function renderLink(label: string, path: string) {
    return h(NuxtLink, { to: path }, { default: () => label })
}

const route = useRoute()

const activeKey = ref("/")

const options: MenuOption[] = [
    {
        label: () => renderLink("Home", "/"),
        key: "/",
    },
    {
        label: () => "Verwalten",
        key: "/manage",
        children: [
            {
                key: "/manage/supplier",
                label: () => renderLink("Hersteller", "/manage/supplier"),
            },
            {
                key: "/manage/products",
                label: () => renderLink("Produkte", "/manage/products"),
            },
        ],
    },
    {
        label: () => "Tools",
        key: "/tools",
        children: [
            {
                key: "/tools/pictures",
                label: () => renderLink("Bild Verarbeitung", "/tools/pictures"),
            },
            {
                key: "/tools/compare",
                label: () => renderLink("Vergleichen", "/tools/compare"),
            },
            {
                key: "/tools/translate",
                label: () => renderLink("Übersetzen", "/tools/translate"),
            },
            {
                key: "/tools/image_download",
                label: () => renderLink("Bild Download", "/tools/image_download"),
            },
        ],
    },
    {
        label: () => "Prodigy",
        key: "/prodigy",
        children: [
            {
                key: "/prodigy/merkmale",
                label: () => renderLink("Merkmale", "/prodigy/merkmale"),
            },
            {
                key: "/prodigy/title",
                label: () => renderLink("Title", "/prodigy/title"),
            },
        ],
    },
    {
        label: () => renderLink("Lagerbestände", "/inventory"),
        key: "/inventory",
    },
    {
        label: () => renderLink("Tasks", "/tasks"),
        key: "/tasks",
    },
    {
        label: () => "Einstellungen",
        key: "/settings",
        children: [
            {
                key: "/settings/category",
                label: () => renderLink("Kategorie", "/settings/category"),
            },
            {
                key: "/settings/glossaries",
                label: () => renderLink("Glossar", "/settings/glossaries"),
            },
        ],
    },
]

for (const option of options) {
    if (option.key === route.fullPath) {
        activeKey.value = option.key
        break
    } else if (option.children) {
        for (const child of option.children) {
            if (child.key === route.fullPath) {
                activeKey.value = child.key as string
                break
            }
        }
    }
}
</script>

<template>
    <div
        class="flex basis-full h-12 drop-shadow relative z-10"
        :style="`
    background-color: ${themeVars['cardColor']};
    transition: 0.3s ${themeVars['cubicBezierEaseInOut']};
    `"
    >
        <base-sunga-logo class="p-1.5" />
        <n-menu v-model:value="activeKey" mode="horizontal" :options="options" class="self-center" />
        <base-theme-toggle class="ml-auto justify-self-center self-center" />
        <base-logout-button class="self-center mr-2" />
    </div>
</template>

<style scoped lang="scss"></style>
